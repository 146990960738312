<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Relay Races</h1>
      <p>
        Todo
      </p>
    </div>

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Easily transition to virtual events and challenges</h2>
            <h1 class="subtitle">Use sodisp to create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <h4>Auto-sync with Garmin, Fitbit, Apple Watch and more</h4>
          <p>
            Effortless add integration with Garmin, Polar, Suunto, Fitbit or COROS devices and the MapMyRun app to any product or service using our fitness-engine-as-a-service! 
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>


        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Best result, total distance, total elevation and more</h4>
          <p>
            We support all popular challenge modes: best result, total distance or total elevation. Even better: you can combine them in the same virtual challenge!
          </p>
          <p> 
            Whether your virtual event/challenge runs for a day, a week or even multiple months, whether it is a single sport or a multi-sport event, you can host it on sodisp.
          </p>
        </v-col>
        

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            We support all popular sports and you can combine them all in the same challenge.
          </p>
          <p> 
            Use our platform to host virtual triathlons, multi-sport events or any combinations of different activity types.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals based on distance or elevation completed and award badges to keep your participants motivated to make it to the (virtual) finish line! Use our consecutive challenge to motivate your participants to keep going and our location based badges for virtual treasure hunts or embed local content into your challenge.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or team races</h4>
          <p>
            Create both individual or team challenges, or combinations of both! With the team functionality any group can work together to reach their common goals and compete with the other registered teams for first place!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>Connect with your official race registration data</h4>
          <p>
            Easily load your registration data to create official results and allow only registered participants to join your virtual event.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Get started for free</h4>
          <p> 
            Get started with sodisp for free. <!-- See our <router-link to="/pricing">simple and fair pricing</router-link> for details.  --><router-link to="/contact">Contact us</router-link> to discuss how sodisp can help your virtual event or challenge.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <iframe src="https://cdn.forms-content.sg-form.com/bad6a860-8ea4-11ea-90d0-222d5e1f3e65" style="width:100%;border:0; height:400px;" noborder border="0" />

  </div>
</template>


<script>
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>